<template>
  <el-input
    v-model="inputVal"
    v-uppercase
    :clearable="clearable"
    :disabled="disabled"
    class="iq-input"
    :placeholder="placeholder"
    :fetch-suggestions="options"
    @input="handleInput"
    @keydown.native.enter="handleKeydownEnter"
  >
    <template v-if="isHowSuffixName" slot="suffix">
      <Icon :name="suffixName" width="20px" height="20px" />
    </template>

    <Icon v-if="isHowPrefixName" slot="prefix" name="prefixName" />
  </el-input>
</template>

<script>
import Icon from '@/UI/icon/Icon.vue'
export default {
  name: 'IqInput',
  components: { Icon },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Array,
      require: true,
    },
    value: {
      required: true,
    },
    placeholder: {
      type: String,
      default: '',
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    suffixName: {
      type: String,
      default: '',
    },
    prefixName: {
      type: String,
      default: '',
    },
  },

  computed: {
    inputVal: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      },
    },
    isHowSuffixName() {
      return this.suffixName !== ''
    },
    isHowPrefixName() {
      return this.prefixName !== ''
    },
  },
  methods: {
    handleInput(event) {
      this.$emit('onInput', event)
    },
    handleKeydownEnter(event) {
      this.$emit('onKeydownEnter', event)
    },
  },
}
</script>

<style lang="sass">
.iq-input
  .el-input__inner
    background: #F5F7FA
    border: 1px solid #DCDFE6
    border-radius: 4px
    font-weight: 500
    font-size: 14px
    line-height: 22px
    font-feature-settings: 'tnum' on, 'lnum' on
    color: #606266
    &::placeholder
      color: #606266
  &.el-form-item
    margin-bottom: 20px!important
  .el-input__suffix
    display: flex
    justify-content: center
    align-items: center
  .el-input__suffix-inner
    height: 100%
    display: flex
    justify-content: center
    align-items: center
  &.el-input--suffix .el-input__inner
    padding-right: 60px
  .el-input__suffix
    right: 13px
</style>
